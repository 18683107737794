import { Button, Card, Checkbox, Input } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";

function PersonalInfo(props) {
  const [checked, setchecked] = useState(false);
  const [open, setOpen] = useState(false); //개인정보이용동의 다이얼로그 열기
  const [userEmail, setUserEmail] = useState(""); //유저 이메일

  const onChange = (e) => {
    //이메일 받기
    setUserEmail(e.target.value);
  };

  const handleOpen = () => {
    setOpen(!open);
    setchecked(false);
  }; //다이얼로그 상태관리

  const Allcheck = () => {
    setchecked(!checked);
  };
  return (
    <div class="m-4">
      <Fragment>
        <div className="flex justify-center">
          <span
            className={`text-lg xs:text-2xl sm:text-3xl text-white ${
              !props.media ? "bg-gray-600" : "bg-purple-400"
            }  p-3 rounded-lg shadow-lg`}
            onClick={!props.media ? null : handleOpen}
          >
            전송하기
          </span>
        </div>
        <Dialog
          open={open}
          handler={handleOpen}
          size="xl"
          className="max-h-full p-2 m-10 overflow-auto"
        >
          <div className="p-3">
            <h3 className="mb-1">
              피드백을 받고싶다면 이메일을 적고 개인정보수집에 동의해주세요!
            </h3>
            <Input label="Email" onChange={onChange} />
          </div>
          <div className="flex justify-end"></div>
          <h1 className="text-lg font-bold m-1">개인정보수집동의서</h1>
          <DialogBody divider>
            <details>
              <summary>펼치기</summary>
              <div markdown="1">
                <Card className="m-4 p-5">
                  <caption class="text-xl">[이메일 수집 동의서]</caption>
                  <br />
                  <p>
                    GroundZero는 서비스 개발 및 홍보/마케팅을 위해서 아래와 같이
                    개인 정보를 수집 및 이용하고자 합니다.
                  </p>
                  <br />
                  <p class="text-sm p-2">개인정보 수집 및 이용 내역</p>
                  <table class="table-auto p-2 bg-blue-gray-50 border-gray-900">
                    <thead class="border border-black">
                      <tr>
                        <th class="border border-black text-sm">항목</th>
                        <th class="border border-black text-sm">
                          수집 및 이용목적
                        </th>
                        <th class="border border-black text-sm">
                          보유 및 이용기간
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-black text-center text-sm">
                          이메일
                        </td>
                        <td class="border border-black text-center text-sm">
                          서비스 개발, 홍보 및 마케팅
                        </td>
                        <td class="border border-black text-center text-sm">
                          3 년
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Checkbox
                    color="purple"
                    label="동의"
                    checked={checked ? true : false}
                  />
                  <br />
                  <br />
                  <caption class="text-xl">[오디오 데이터 수집 동의서]</caption>
                  <br />
                  <p>
                    GroundZero는 서비스 개발 및 연구 활동을 위해서 아래와 같이
                    개인 정보를 수집 및 이용하고자 합니다.
                  </p>
                  <br />
                  <p class="text-sm p-2">개인정보 수집 및 이용 내역</p>
                  <table class="table-auto p-2 bg-blue-gray-50 border-gray-900">
                    <thead class="border border-black">
                      <tr>
                        <th class="border border-black text-sm">항목</th>
                        <th class="border border-black text-sm">
                          수집 및 이용목적
                        </th>
                        <th class="border border-black text-sm">
                          보유 및 이용기간
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-black text-center text-sm">
                          오디오 데이터
                        </td>
                        <td class="border border-black text-center text-sm">
                          서비스 개발, 홍보 및 마케팅
                        </td>
                        <td class="border border-black text-center text-sm">
                          3 년
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Checkbox
                    color="purple"
                    label="동의"
                    checked={checked ? true : false}
                  />
                </Card>
              </div>
            </details>
          </DialogBody>
          <Checkbox color="purple" label="전체동의" onClick={Allcheck} />
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>취소</span>
            </Button>
            <Button
              variant="gradient"
              color="purple"
              disabled={checked && userEmail !== "" ? false : true}
              onClick={() => {
                props.recoUpload(userEmail);
                handleOpen();
              }}
            >
              <span>확인</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </Fragment>
    </div>
  );
}

export default PersonalInfo;

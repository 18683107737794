import "../styles/sound_wave.css";

function Soundwave(props) {
  function waveRe() {
    let array2 = [];
    for (let i = 0; i < 10; i++) {
      array2.push(<i key={i} className="bar"></i>);
    }
    return array2;
  }

  return (
    <div className="fuck_body hidden xs:inline">
      {/* 어떻게 크기를 조절할까? 그냥 없앴다... */}
      <div className="sound-icon disabled">
        <div className="sound-wave">{props.onRec ? "" : waveRe()}</div>
      </div>
      {/* 시간 */}
      <div className="flex justify-center">
        <span className="text-xl font-semibold">
          {props.minute}:{props.second}
        </span>
      </div>
    </div>
  );
}

export default Soundwave;

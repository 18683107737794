import Home from "./view/Home.js";
import { Route, Routes } from "react-router-dom";
import "tailwindcss/tailwind.css";
import PersonalInfo from "components/Personal_info.js";
import FeedBack from "pages/feedback.js";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/per_info" element={<PersonalInfo />} />
        <Route path="/feedback" element={<FeedBack />} />
      </Routes>
    </div>
  );
}

export default App;

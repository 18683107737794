const { MdOutlineKeyboardArrowDown } = require("react-icons/md");
const { default: NavBar } = require("./NavBar");

export default function Hero() {
  return (
    <div className="h-screen flex justify-center bg-gradient-to-t from-third via-secondary to-primary">
      <NavBar />
      <div className="relative mt-12">
        <h1 className="text-left xl:text-center m-4 mt-16 text-6xl xl:text-8xl font-quantify drop-shadow-lg tracking-tight">
          BEYOND
          <br />
          EVERY
          <br />
          EMOTION
        </h1>
        <h3 className="text-center w-64 m-4 text-lg">
          "무드아일랜드는 목소리를 통해 당신의 감정을 분석합니다."
        </h3>
        <div className="flex justify-center">
          <div className="absolute bottom-14">
            <h3 className="text-center w-56 m-4">
              클릭한번으로 간편하게 당신의 감정을 기록해보세요
            </h3>
            {/* 움직이는 애니메이션 */}
            <div className="animate-bounce 2s flex justify-center text-5xl">
              <MdOutlineKeyboardArrowDown></MdOutlineKeyboardArrowDown>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

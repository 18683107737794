import Footer from "layout/Footer";
import Hero from "layout/Hero";
import VoiceTest from "layout/VoiceTest";
import Fullpage, {
  FullPageSections,
  FullpageSection,
} from "@ap.cx/react-fullpage";

function Home() {
  return (
    <div className="font-sans">
      <Fullpage>
        <FullPageSections>
          <FullpageSection>
            <Hero />
          </FullpageSection>
          <FullpageSection>
            <div className="h-screen">
              <VoiceTest />
              <Footer />
            </div>
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    </div>
  );
}

export default Home;

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

function Cartegory() {
  const data = [
    {
      label: "직장",
      value: "workplace",
      desc: `직장에서 무슨일이 있었나요? 그럴 때마다 어떤 감정이 드시나요?`,
    },
    {
      label: "연인",
      value: "lover",
      desc: `사랑하는 사람과 행복한 시간을 보내셨나요? 
      사랑하는 사람에게 고마웠던일 또는 서운한일이 있었나요? 그럴 때마다 어떤 감정이 드시나요?`,
    },

    {
      label: "친구",
      value: "friends",
      desc: `오랜만에 친구를 만나셨나요? 무엇을 하셨나요? 어떤 감정이 들었나요?`,
    },

    {
      label: "가족",
      value: "family",
      desc: `가족과 함께 행복한 시간을 보내셨나요? 가족들에게 속상한일이 있었나요? 어떤 감정이 들었나요?`,
    },

    {
      label: "진로",
      value: "future",
      desc: `진로에 대한 걱정때문에 고민이 많으신가요? 어떤 감정이 들었나요?`,
    },
  ];

  return (
    <div className="m-3 flex justify-center">
      <Tabs value="workplace">
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
}

export default Cartegory;

import React from "react";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import Logo from ".././assets/images/logo_black.png";
import feedback from ".././assets/images/feedback.png";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <div className="absolute w-screen flex items-center justify-between px-3 py-1 shadow-lg">
      <Link to="/">
        <img src={Logo} alt="moodisland" className="w-10" />
      </Link>
      <div className="flex justify-center gap-2">
        <Link to="/feedback">
          <img src={feedback} alt="feedback" className="w-9" />
        </Link>
        <RWebShare
          data={{
            text: "감정 큐레이팅 서비스, 무드 아일랜드",
            url: "https://www.moodisland.kr",
            title: "Mood Island | 무아",
          }}
          onClick={() => console.log("공유 성공")}
        >
          <FaShareAlt className="text-3xl" />
        </RWebShare>
      </div>
    </div>
  );
}

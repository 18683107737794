import { Card, CardBody, Typography } from "@material-tailwind/react";
import NavBar from "layout/NavBar";
import moment from "moment";
import "moment/locale/ko";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function FeedBack(props) {
  const [seldate, setSeldate] = useState(new Date());

  return (
    <div className="h-screen flex justify-center bg-gradient-to-t from-third via-secondary to-primary">
      <NavBar />
      <div className="mt-20">
        <div className="flex justify-center">
          <Calendar onChange={setSeldate} value={seldate}></Calendar>
        </div>

        <Card className="m-3 w-auto bg-transparent/40 text-white">
          <CardBody>
            <h3>{moment(seldate).format("ll")}</h3>
            <h2>당신의 감정에 대한 한마디</h2>
            <Typography>
              직장에서 화가 많이 나는 하루를 보내셨군요. 오늘도 힘든 하루를 잘
              넘기느라 수고하셨습니다.
            </Typography>
            <br />
            <Typography>
              격앙된 목소리, 고르지 않은 말의 빠르기로 보아 당신은 상당히 분노한
              상태입니다. 따뜻한 차한잔 하면서 생각을 정리해보세요
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

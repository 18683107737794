import "../styles/Simte_background.css";

const Footer = () => {
  const handleCopy = (e) => {
    e.preventDefault();
    e.clipboardData.setData("Text", this.state.text);
  };

  return (
    <footer className="fixed-bottom w-screen py-2 px-2 text-xs">
      <div className="absolute">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <div className="relative">
        <p>Team. GroundZero</p>
        <span onClick={(e) => handleCopy(e)}>
          groundxzero.official@gmail.com
        </span>
        <div className="flex justify-between">
          <a className="mt-3" href="https://instagram.com/moodisland_official">
            <img
              alt="instagram"
              src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
              className="max-h-10"
            />
          </a>
          <div className="mt-8">© 2023 GroundZero All rights reserved</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

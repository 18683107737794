import React, { useEffect, useState } from "react";
import { storageSv, db } from "../fire_base";
import { ref, uploadBytes } from "firebase/storage";
import { Alert, Button } from "@material-tailwind/react";
import { MdRecordVoiceOver } from "react-icons/md";
import PersonalInfo from "components/Personal_info";
import Soundwave from "components/Sound_wave";
import { doc, setDoc } from "firebase/firestore";
import { useReactMediaRecorder } from "react-media-recorder";
import Cartegory from "components/Cartegory";

function VoiceTest() {
  const [counter, setCounter] = useState(0);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [uploadSucc, setuploadSucc] = useState(false); //true이면 업로드완료
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
      blobPropertyBag: {
        type: "audio/wav",
      },
    });

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;
        setSecond(computedSecond);
        setMinute(computedMinute);
        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  const onSubmitAudioFile = async (Email) => {
    let storageRef = ref(storageSv, `audiofile_${Email}`);

    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const sound_file = new File([audioBlob], "soundBlob", {
      lastModified: new Date().getTime(),
      type: "audio",
      name: Email,
    });
    // console.log(sound_file);
    //데이터 전송
    uploadBytes(storageRef, sound_file).then(async (snapshot) => {
      await setDoc(
        doc(db, "User_email", Email),
        {
          email: Email,
          voice_data: `audiofile_${Email}`,
        },
        { merge: true }
      );

      setuploadSucc(true); //알러트 표시
      console.log("데이터 등록완료!");
    });
  };

  return (
    <div>
      <div>{/* 전송완료 페이지 만들자 */}</div>
      <div className="flex justify-center">
        <h1 className="antialiased text-lg xs:text-2xl drop-shadow-lg font-bold tracking-tight mt-5">
          당신의 감정을 자유롭게 이야기 해보세요
        </h1>
      </div>
      <Cartegory />

      <Alert
        className="bg-secondary text-black"
        show={uploadSucc}
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
        dismissible={{
          onClose: () => setuploadSucc(false),
        }}
      >
        데이터 등록완료!
      </Alert>

      <div className="relative overflow-hidden">
        {/* 오디오 미리듣기 */}
        <div className="flex justify-center">
          <audio controls src={mediaBlobUrl} hidden={!mediaBlobUrl} />
        </div>
        <div className="grid justify-center sm:gap-3">
          <Soundwave
            onRec={status === "recording" ? false : true}
            minute={minute}
            second={second}
          />
          <div className="text-center mt-2">
            <Button
              onClick={() => {
                if (!isActive) {
                  startRecording();
                } else {
                  stopRecording();
                  stopTimer();
                }
                setIsActive(!isActive);
              }}
              color={status === "recording" ? "purple" : "gray"}
              className={
                status === "recording"
                  ? "rounded-2xl animate-pulse"
                  : "rounded-2xl"
              }
            >
              <MdRecordVoiceOver className="text-4xl sm:text-5xl" />
            </Button>
          </div>
          <PersonalInfo recoUpload={onSubmitAudioFile} media={mediaBlobUrl} />
        </div>
      </div>
    </div>
  );
}

export default VoiceTest;
